import { Route } from 'core/libs/router';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import LegalPage from 'core/components/LegalPage';
import { Mobile, Desktop } from 'core/components/breakpoint';
import RulesRecommenders from 'core/components/RulesRecommenders';

import Layout from 'site/components/Layout';
import GoogleDBM from 'site/components/GoogleDBM';
import StarsBirthdayProvider from 'site/components/StarsBirthdayContext';

import {
  Shtorka,
  Background,
  Fullscreen as DesktopFullscreen,
} from './components/Ads/desktop';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from './components/Ads/mobile';

import Main from './pages/main';
import Topic from './pages/topic';
import About from './pages/about';
import Rubric from './pages/rubric';
import Tag from './pages/tag';
import Horoscope from './pages/horoscope';
import Search from './pages/search';
import Stars from './pages/stars';
import Specprojects from './pages/specprojects';
import Comments from './pages/comments';
import Photo from './pages/photo';
import News from './pages/news';
import Authors from './pages/Authors';
import Author from './pages/Author';
import Star from './pages/star';
import Profile from './pages/Profile';
/**
 * TODO: реализовать на бэке ручку, отдающую словари с константами для сайта.
 *
 * Имеющийся кейс: список знаков зодиака и диапазон дат рождения хранятся
 * на бэке как константы и отдаются ручкой /topics только вместе с остальными
 * данными топика.
 *
 * Также может возникнуть необходимость получения из апи констант, специфичных
 * для проект.
 */

/**
 * Для топика типа звезда изменилось формировние URL.
 * В топике свойство link: `stars/<slug_tag>` приходит с тегом, а не `.htm`
 * Добавил кастомный Route, теперь топики типа "Звезда" могут открываться
 * с `.htm` и с тегом `stars/<slug_tag>`
 *
 * TODO: Бекенд задача по переопределению URL с тегом для топка Звезда
 * находится в тестировании. Нужно будет проверить.
 */

export default function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  return (
    <App {...config}>
      <StarsBirthdayProvider>
        <GoogleDBM />
        <Layout>
          <BaseRoutes
            main={Main}
            topic={Topic}
            about={About}
            rubrics={Rubric}
            tags={Tag}
            search={Search}
            comments={Comments}
            authors={Authors}
            author={Author}
            profile={Profile}
            legal={LegalPage}
            rulesRecommenders={RulesRecommenders}
          >
            <Route
              path='/photo'
              component={Photo}
            />
            <Route
              path='/news'
              component={News}
            />
            <Route
              exact
              path='/horoscope'
              component={Horoscope}
            />
            <Route
              exact
              path='/stars'
              component={Stars}
            />
            <Route
              exact
              path='/stars/:level_2([a-z0-9\-_]+)'
              component={Star}
            />
            <Route
              exact
              path='/special-projects'
              component={Specprojects}
            />
          </BaseRoutes>
        </Layout>
        <Desktop>
          <Background />
          <Shtorka />
          <DesktopFullscreen />
        </Desktop>
        <Mobile>
          <Rich />
          <MobileFullscreen />
        </Mobile>
      </StarsBirthdayProvider>
    </App>
  );
}
